import cx from 'classnames';

const divClasses = (className) => cx(
	'h-40 w-full flex flex-col justify-end p-4 mb-8 text-text-slate-900 dark:text-slate-100',
	className,
);

function PageHeader({ className, greeting, children }) {
	return (
		<div className={divClasses(className)}>
			<div className="text-5xl font-extralight">
				{greeting}
			</div>
			{children}
		</div>
	);
}

export default PageHeader;
