import { useEffect } from 'react';
import { PageHeader, Article } from '../components';
import { about } from '../../content';

function About() {
	useEffect(() => {
		document.title = 'About | Bruno Péjac';
	}, []);

	return (
		<>
			<PageHeader className="bg-yellow-300 dark:bg-yellow-600" greeting="About" />
			<Article text={about} />
		</>
	);
}

export default About;
