import { marked } from 'marked';
import { useNavigate } from 'react-router-dom';

function Article({
	children,
	date,
	id,
	text,
}) {
	const navigate = useNavigate();
	const renderer = new marked.Renderer();

	renderer.link = (href, title, label) => {
		const link = marked.Renderer.prototype.link.call(renderer, href, title, label);
		if (href.startsWith('/')) {
			return link.replace(/href="(.*?)"/, `data-href="${href}" href="javascript:;" `);
		}
		return link.replace(/^<a /, '<a target="_blank" rel="noopener noreferrer" ');
	};

	const handleClick = (e) => {
		const { href } = e.target.dataset;
		if (href) {
			e.preventDefault();
			navigate(href);
		}
	};

	const html = text ? marked(text, { renderer }) : '';

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
		<article
			className="text-slate-900 dark:text-slate-100 text-justify mb-8 w-full border-y md:border-x border-slate-300 dark:border-slate-800 flex flex-col items-stretch"
			id={id}
			onClick={handleClick}
		>
			{(date || id) && (
				<div className="flex justify-between text-slate-500">
					<small className="m-2">{date}</small>
					{id && (
						<a href={`#${id}`} title="Permalink" className="h-12 w-12 flex items-center justify-center hover:bg-slate-200 dark:hover:bg-slate-800">
							<svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
							</svg>
						</a>
					)}
				</div>
			)}
			<div className="m-4">
				{children}
				{/* eslint-disable-next-line react/no-danger */}
				{text && <div className="article_markdown" dangerouslySetInnerHTML={{ __html: html }} />}
			</div>
		</article>
	);
}

export default Article;
