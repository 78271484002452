import { useEffect } from 'react';
import {
	Article,
	PageHeader,
} from '../components';
import { projects } from '../../content';

function Projects() {
	useEffect(() => {
		document.title = 'Projects | Bruno Péjac';
	}, []);

	return (
		<>
			<PageHeader greeting="Projects" className="bg-sky-400 dark:bg-sky-900" />
			{projects.map(({
				date,
				id,
				text,
			}, index) => (
				<Article
					date={date}
					id={id}
					key={`project_${index}_${id}`}
					text={text}
				/>
			))}
		</>
	);
}

export default Projects;
