// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {

    height: 100%;

    width: 100%
}

.article_markdown a {

    cursor: pointer;

    text-decoration-line: underline
}

.article_markdown a:hover {

    --tw-text-opacity: 1;

    color: rgb(7 89 133 / var(--tw-text-opacity))
}

:is(.dark .article_markdown a:hover) {

    --tw-text-opacity: 1;

    color: rgb(125 211 252 / var(--tw-text-opacity))
}

.article_markdown h1 {

    padding-bottom: 1rem;

    font-size: 1.875rem;

    line-height: 2.25rem;

    font-weight: 700;

    line-height: 1.25
}

.article_markdown h2 {

    padding-bottom: 1rem;

    font-size: 1.5rem;

    line-height: 2rem;

    font-weight: 700;

    line-height: 1.25
}

.article_markdown h3 {

    padding-bottom: 1rem;

    font-size: 1.25rem;

    line-height: 1.75rem;

    font-weight: 700;

    line-height: 1.25
}

.article_markdown h4 {

    padding-bottom: 1rem;

    font-size: 1.125rem;

    line-height: 1.75rem;

    font-weight: 700;

    line-height: 1.25
}

.article_markdown h5 {

    padding-bottom: 1rem;

    font-weight: 700;

    line-height: 1.25
}

.article_markdown pre {

    margin-top: 0.5rem;

    margin-bottom: 0.5rem;

    overflow-x: auto;

    --tw-bg-opacity: 1;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity));

    padding: 1rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(241 245 249 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./app.css"],"names":[],"mappings":"AACI;;IAAA,YAAoB;;IAApB;AAAoB;;AAIpB;;IAAA,eAA0E;;IAA1E;AAA0E;;AAA1E;;IAAA,oBAA0E;;IAA1E;AAA0E;;AAA1E;;IAAA,oBAA0E;;IAA1E;AAA0E;;AAI1E;;IAAA,oBAA4C;;IAA5C,mBAA4C;;IAA5C,oBAA4C;;IAA5C,gBAA4C;;IAA5C;AAA4C;;AAI5C;;IAAA,oBAA4C;;IAA5C,iBAA4C;;IAA5C,iBAA4C;;IAA5C,gBAA4C;;IAA5C;AAA4C;;AAI5C;;IAAA,oBAA2C;;IAA3C,kBAA2C;;IAA3C,oBAA2C;;IAA3C,gBAA2C;;IAA3C;AAA2C;;AAI3C;;IAAA,oBAA2C;;IAA3C,mBAA2C;;IAA3C,oBAA2C;;IAA3C,gBAA2C;;IAA3C;AAA2C;;AAI3C;;IAAA,oBAAmC;;IAAnC,gBAAmC;;IAAnC;AAAmC;;AAInC;;IAAA,kBAA+D;;IAA/D,qBAA+D;;IAA/D,gBAA+D;;IAA/D,kBAA+D;;IAA/D,mDAA+D;;IAA/D,aAA+D;;IAA/D,mBAA+D;;IAA/D,oBAA+D;;IAA/D,oBAA+D;;IAA/D;AAA+D","sourcesContent":["html, body, #root {\n    @apply h-full w-full;\n}\n\n.article_markdown a {\n    @apply underline cursor-pointer hover:text-sky-800 dark:hover:text-sky-300;\n}\n\n.article_markdown h1 {\n    @apply text-3xl font-bold leading-tight pb-4;\n}\n\n.article_markdown h2 {\n    @apply text-2xl font-bold leading-tight pb-4;\n}\n\n.article_markdown h3 {\n    @apply text-xl font-bold leading-tight pb-4;\n}\n\n.article_markdown h4 {\n    @apply text-lg font-bold leading-tight pb-4;\n}\n\n.article_markdown h5 {\n    @apply font-bold leading-tight pb-4;\n}\n\n.article_markdown pre {\n    @apply p-4 bg-black text-slate-100 my-2 text-sm overflow-x-auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
