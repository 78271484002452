import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useThrottledCallback } from '../utils/hooks';

const cardClasses = (className) => cx(
	'shadow-lg border border-sky-500 dark:border-sky-900',
	className,
);

export default function Card({
	className,
	children,
}) {
	const [xY, setXY] = useState([0, 0]);
	const cardRef = useRef();

	const handleMouseMove = useThrottledCallback((e) => {
		if (cardRef.current) {
			// get the mouse position relative to the centre of the card
			let x = e.clientX - cardRef.current.offsetLeft - cardRef.current.offsetWidth / 2;
			let y = e.clientY - cardRef.current.offsetTop - cardRef.current.offsetHeight / 2;

			// use percentage of mouse position to determine rotation
			x /= (cardRef.current.offsetWidth / 2);
			y /= (cardRef.current.offsetHeight / 2);

			// values should not be higher than 4 or lower than -4
			x = Math.min(Math.max(x, -4), 4);
			y = Math.min(Math.max(y, -4), 4);

			// increase the values to get a bigger effect
			x *= 1.5;
			y *= 1.5;

			// invert y so that it goes from top to bottom
			y *= -1;

			// set the rotation values
			setXY([x, y]);
		}
	}, 16);

	useEffect(() => {
		if (window.matchMedia && !window.matchMedia('(prefers-reduced-motion)').matches && window.matchMedia('(min-width: 768px)').matches) {
			window.addEventListener('mousemove', handleMouseMove);
		}
		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	return (
		<div
			ref={cardRef}
			style={window.matchMedia && window.matchMedia('(min-width: 768px)').matches
				? { transform: `perspective(200px) rotateY(${xY[0]}deg) rotateX(${xY[1]}deg)` }
				: {}}
			className={cardClasses(className)}
		>
			{children}
		</div>
	);
}
