import {
	useEffect,
	useRef,
	useState,
} from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

const buttonIconWrapperClasses = (showAlt) => cx(
	'flex flex-col items-center h-6 w-6 transition-all',
	{
		'-translate-y-6': showAlt,
	},
);

function Nav({ items, onToggleDarkMode, isDarkMode }) {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef(null);

	const handleClickOutside = (e) => {
		if (ref.current.contains(e.target)) {
			return;
		}
		setIsOpen(false);
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen]);

	return (
		<header className="fixed z-50 h-12 w-full bg-sky-500 dark:bg-sky-900 shadow-lg flex md:justify-center">
			<nav className="w-full text-slate-50 relative" ref={ref}>
				<button
					className="p-2 md:hidden h-12 w-12 flex justify-center items-center hover:bg-sky-400 dark:hover:bg-sky-800"
					onClick={() => setIsOpen(!isOpen)}
					type="button"
				>
					<div className="flex flex-col items-center overflow-hidden h-6 w-6">
						<div className={buttonIconWrapperClasses(!isOpen)}>
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
							</svg>
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
							</svg>
						</div>
					</div>
				</button>
				<div
					className="h-0 md:h-full flex flex-col md:flex-row md:w-full md:flex md:justify-center items-center bg-slate-700 md:bg-sky-500 md:dark:bg-sky-900 shadow-lg md:shadow-none overflow-hidden transition-all"
					style={isOpen ? { height: `${items.length * 3}rem` } : {}}
				>
					{items.map((item) => (
						<NavLink
							key={item.id}
							className="uppercase px-3 flex items-center justify-center hover:bg-sky-400 dark:hover:bg-sky-800 h-12 md:h-full w-full md:w-auto"
							to={item.to}
							onClick={() => setIsOpen(false)}
						>
							{item.title}
						</NavLink>
					))}
				</div>
				<button
					className="absolute top-0 right-0 h-12 w-12 flex items-center justify-center hover:bg-sky-400 dark:hover:bg-sky-800"
					onClick={onToggleDarkMode}
					title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
					type="button"
				>
					<div className="flex flex-col items-center overflow-hidden h-6 w-6">
						<div className={buttonIconWrapperClasses(isDarkMode)}>
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
							</svg>
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
							</svg>
						</div>
					</div>
				</button>
			</nav>
		</header>
	);
}

export default Nav;
