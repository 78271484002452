import { useEffect, useRef, useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
} from 'react-router-dom';
import cx from 'classnames';

import './app.css';

import {
	Footer,
	Nav,
	PageHeader,
	Article,
} from './components';
import {
	About,
	Home,
	Projects,
} from './routes';

const wrapperClasses = (isDarkMode) => cx(
	'w-full h-full',
	{ dark: isDarkMode },
);

const routes = [
	{
		to: '/',
		exact: true,
		element: <Home />,
		title: 'Home',
		id: 'home',
	},
	{
		to: '/projects',
		element: <Projects />,
		title: 'Projects',
		id: 'projects',
	},
	{
		to: '/about',
		element: <About />,
		title: 'About',
		id: 'about',
	},
];

function Route404() {
	useEffect(() => {
		document.title = '404 | Bruno Péjac';
	}, []);

	return (
		<>
			<PageHeader className="bg-slate-200" greeting="404" />
			<Article>
				<div className="p-4">
					Error 404: Page not found
				</div>
			</Article>
		</>
	);
}

function App({ isHydrated }) {
	const wrapperRef = useRef();
	const [isDarkMode, setIsDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (window.matchMedia) {
			const handleColorSchemeChange = (event) => {
				setIsDarkMode(event.matches);
			};
			window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleColorSchemeChange);
			return () => {
				window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleColorSchemeChange);
			};
		}
	}, []);

	useEffect(() => {
		if (isHydrated) {
			if (!wrapperRef.current.classList.contains('dark') && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
				wrapperRef.current.classList.add('dark');
			}
		}
	}, [isHydrated]);

	return (
		<Router onUpdate={() => window.scrollTo(0, 0)}>
			<div ref={wrapperRef} className={wrapperClasses(isDarkMode)}>
				<div className="flex flex-col items-center relative w-full h-full overflow-y-auto overflox-x-hidden bg-slate-50 dark:bg-slate-900 transition-all">
					<Nav
						isDarkMode={isDarkMode}
						items={routes}
						onToggleDarkMode={() => setIsDarkMode(!isDarkMode)}
					/>
					<main className="flex-grow max-w-3xl mt-20 flex flex-col items-center w-full">
						<Routes>
							{routes.map((route) => (
								<Route
									element={route.element}
									exact={route.exact}
									key={route.id}
									path={route.to}
								/>
							))}
							<Route path="*" element={<Route404 />} />
						</Routes>
					</main>
					<Footer>
						Berlin
						<span className="px-1" role="img" aria-label="electric shock">⚡</span>
						{(new Date()).getFullYear()}
					</Footer>
				</div>
			</div>
		</Router>
	);
}

export default App;
